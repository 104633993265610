import { registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { Router } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';

import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoginService } from '@app/auth/login/login.service';
import { ConfEventService } from '@app/confEvent/confEvent.service';
import { DashboardService } from '@app/dashboard/dashboard.service';
import { ProductsService } from '@app/products/products.service';
import { ProfitsService } from '@app/profits/profits.service';
import { RoomsService } from '@app/rooms/rooms.service';
import { VoucherService } from '@app/voucher/voucher.service';
import { WorkersService } from '@app/workers/workers.service';

import { Bracelit404Module } from '@app/shared/bracelit-components/bracelit-404';
import { BRACELIT_FORM_ITEM_DEFAULT_APPEARANCE } from '@bracelit-components/common/bracelit-forms';
import { OrdersService } from '@app/orders/orders.service';
import { GroupsService } from './groups/groups.service';
import { ActivitiesService } from './activities/activities.service';
import { ShoppingCartService } from '@shared/services/shopping-cart.service';
import { AppCustomPreloader } from '@shared/preloader/app-custom-preloader';
import {
  AuthenticationService,
  GlobalArraysService,
  PwaService,
  ThemeService,
  TitleMetaService,
  ToggleSidebarService
} from '@shared/services';
import { DEFAULT_THEME } from '@shared/services/theme.service';
import { SharedModule } from '@shared/shared.module';
import { NgxImageCompressService } from 'ngx-image-compress';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateConfigService } from './shared/services/translate-config.service';
import { EventsComponent } from './events/events.component';
import { EventsFormComponent } from './events/events-form/events-form.component';
import { EventsEditFormComponent } from './events/events-edit-form/events-edit-form.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationsDialogComponent } from './notifications/notifications-dialog/notifications-dialog.component';
import * as Sentry from "@sentry/angular";
import { CancelOrderDialogComponent } from './orders/cancel-order-dialog/cancel-order-dialog.component';
import { LoaderService } from './shared/services/loader.service';
import { AssociateFormComponent } from './data-base/associate-form/associate-form.component';
import { VouchersEditComponent } from './data-base/vouchers-edit/vouchers-edit.component';


registerLocaleData(localeEs);

export function rootLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({ declarations: [
        AppComponent,
        EventsComponent,
        EventsFormComponent,
        EventsEditFormComponent,
        NotificationsComponent,
        NotificationsDialogComponent,
        CancelOrderDialogComponent,
        AssociateFormComponent,
        VouchersEditComponent
        // DevicesComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule.forRoot(),
        MatTableModule,
        // ServiceWorkerModule.register('/custom-service-worker.js', {enabled: environment.production}),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        HammerModule,
        Bracelit404Module,
        // ServiceWorkerModule.register('/ngsw-worker.js', {
        //   enabled: environment.production,
        //   // Register the ServiceWorker as soon as the app is stable
        //   // or after 30 seconds (whichever comes first).
        //   registrationStrategy: 'registerWhenStable:30000'
        // })
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (rootLoaderFactory),
                deps: [HttpClient]
            }
        })], providers: [
        AuthenticationService,
        GlobalArraysService,
        PwaService,
        TitleMetaService,
        ToggleSidebarService,
        AppCustomPreloader,
        ThemeService,
        DashboardService,
        ProfitsService,
        ProductsService,
        WorkersService,
        OrdersService,
        RoomsService,
        GroupsService,
        ActivitiesService,
        VoucherService,
        ConfEventService,
        ShoppingCartService,
        NgxImageCompressService,
        TranslateConfigService,
        LoginService,
        LoaderService,
        { provide: DEFAULT_THEME, useValue: 'bracelit-bracelit-theme' },
        { provide: BRACELIT_FORM_ITEM_DEFAULT_APPEARANCE, useValue: 'standard' },
        { provide: LOCALE_ID, useValue: 'es' },
        { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false, }), },
        { provide: Sentry.TraceService, deps: [Router], },
        { provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [Sentry.TraceService], multi: true, },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
}
