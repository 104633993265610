// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // protocol: 'http://',
  // server: 'localhost:8881/',
  protocol: 'https://',
  server: 'test.bracelit.es/',
  // server: 'api.v5.bracelit.es/',
  apiUrl: 'api/v1/',
  redsysUrl: 'https://sis-t.redsys.es/sis/realizarPago'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
