import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { DashboardService } from '@app/dashboard/dashboard.service';
import { ProductsService } from '@app/products/products.service';
import { ProfitsService } from '@app/profits/profits.service';
import { RoomsService } from '@app/rooms/rooms.service';
import { UsersService } from '@app/users/users.service';
import { VoucherService } from '@app/voucher/voucher.service';
import { WorkersService } from '@app/workers/workers.service';
import { GroupsService } from '@app/groups/groups.service';
import { ActivitiesService } from '@app/activities/activities.service';

import { Product } from '@shared/models/product';
import { ApiService } from '@shared/services/api.service';
import { AuthenticationService } from '@shared/services/authentication.service';
import { SnackBarService } from './snack-bar.service';

@Injectable({providedIn: 'root'})
export class DataStorageService {
  myForm: UntypedFormGroup;
  product: Product;
  private userId: any;
  private eventId: any;
  public errorMoveEdit: any;
  private worker: any;
  private workerPass: { password: any; surname: any; roleId: any; name: any; usuario: any; email: any};
  constructor(private dashboardService: DashboardService,
              private productService: ProductsService,
              private profitsService: ProfitsService,
              private userService: UsersService,
              private voucherService: VoucherService,
              private workerService: WorkersService,
              private roomService: RoomsService,
              private apiService: ApiService,
              private authService: AuthenticationService,
              private groupService: GroupsService,
              private activitiesService : ActivitiesService,
              private snackbarService: SnackBarService) {
  }

  fetchNotifications(subject:string) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/getNotificationReport',{
        subject: subject
      });
  }

  fetchNotifications2() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/getNotificationReport');
  }

  fetchSubjects(startingDate: string, endingDate: string){
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/subjectList', '&startingDate=' + startingDate + '&endingDate=' + endingDate);
  }

  fetchEvents() {
    return this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/events');
  }

  getOfflineUsers(currentQuery: any, perPage: any) {
    return this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/showDataWristbands', '&perPage=' +perPage+'&page='+currentQuery);

      //.post('accounts/' + this.authService.getAccountId()['id'] + '/showDataWristbands' + '?perPage=' + currentQuery + '&page=' + perPage);
  }

  getOfflineMoney(id: any) {
    return this.apiService
      .get('wristbands/' + id + '/showMoneyWristband');
  }

  dissasociateOfflineWristband(id: any) {
    return this.apiService
      .post('wristbands/' + id + '/disassociateWristband', {});
  }

  setWristbandMoney(id: any, money: any) {
    return this.apiService
      .post('wristbands/' + id + '/updateWallet' , {money});
  }

  setIsActive(accountId: any, eventId: any, physicalId: any){
    return this.apiService
      .patch('accounts/' + accountId + '/events/' + eventId + '/wristbands/' + physicalId + '/activateOrDeactivate', {})
  }

  searchOfflineUsers(bracel: any, currentQuery: any, perPage: any){
    return this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/showDataWristbands', '&perPage=' +perPage + '&page=' + currentQuery + '&query=' + bracel);

  }

  setEventId(id: string) {
    this.eventId = id;
  }

  fetchEventInfo() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/infoEvent');
  }

  fetchEvent() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    this.userId = JSON.parse(localStorage.getItem('userId'));
    return this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/eventUserTotalParams', 'query=&userId=' + this.eventId);
  }

  updateEvent(updateData: any) {
    this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/updateEvent', updateData)
      .subscribe(data => {
        localStorage.setItem('Event', JSON.stringify(data));
      },
      err => {
        console.error(err);
      });
  }

  updateEvent2(updateData2: any) {
    const updateData = new FormData();
    for (const key in updateData2) { // Agrega todos los campos al updateData (incluido el archivo)
      if (updateData2.hasOwnProperty(key)) {
        updateData.append(key, updateData2[key]);
      }
    }

    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/updateEvent', updateData)
      .subscribe(data => {
        localStorage.setItem('Event', JSON.stringify(data));
      },
      err => {
        console.error(err);
      });
  }

  updateEventWithAccount(updateData: any, eventId: string) {
    this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + eventId + '/updateEvent', updateData)
      .subscribe(_data => {
      },
      err => {
        console.error(err);
      });
  }

  deleteEventWithAccount(eventId: string) {
    this.apiService
      .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + eventId + '/deleteEvent')
      .subscribe(data => {
      },
      err => {
        console.error(err);
      });
  }


  createEvent(createData: FormData) {
    return this.apiService
      .post('automaticCustomInterface', createData);
  }

  createEventWithAccount(createData: any) {
    return this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/automaticCustomInterfaceWithAccount', createData);
  }

  storeProducts(product: Product, image: FormData) {
    const productPass = {name: product['name'], price: product['price'], product_category_id: product['productCategoryId'].id, onlyVoucher: product['onlyVoucher'], stock: product['stock'], restrictAge: product['restrictAge'], secondaryPrice: product['secondaryPrice']};
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/newProductattach', productPass)
      .subscribe(response2 => {
          const respon = response2;
          respon.price = productPass.price;
          this.productService.addNewProduct(respon);
          this.apiService.post('files/' + respon.id, image).subscribe((_response) => {
          },
          err => {
            console.error(err);
          });
          /*this.apiService.post('files/' + respon.id, image).subscribe((_response) => {
            },
        err => {
          console.error(err);
        });*/
        },
        err => {
          console.error(err);
        });
  }

  fetchEventProducts() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listProducts');
  }

  updateProduct(product: Product, id: string) {
    const productEditPass = {NewName: product['name'], NewPrice: product['price'], product_category_id: product['productCategoryId'].id, onlyVoucher: product['onlyVoucher'], stock: product['stock'], isPurchableOnline: product['isPurchableOnline'], restrictAge: product['restrictAge'], secondaryPrice: product['secondaryPrice']};

    return this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/products/' + id + '/updateProduct', productEditPass);
  }

  deleteProduct(id: string) {
    return this.apiService
      .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/products/' + id);
  }

  storeCategories(category: any) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/newProductCategory', category)
      .subscribe(_response => {
        this.fetchCategories().subscribe(data2 => {
            this.productService.setCategories(data2);
            this.dashboardService.setCategories(data2);
          },
          err => {
            console.error(err);
          });
        },
        err => {
          console.error(err);
        });
  }

  fetchCategories() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listProductCategories');
  }

  updateCategory(category: { [x: string]: any; }, id: string) {
    const categoryEditPass = {NewName: category['name'], NewIcon: category['icon'], weight: category['weight']};
    this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/product-categories/' + id + '/updateCategory', categoryEditPass)
      .subscribe(data => {
        this.productService.setCategories(data);
        this.dashboardService.setCategories(data);
      },
      err => {
          console.error(err);
      });
  }

  deleteCategory(id: string) {
    return this.apiService
      .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/product-categories/' + id);
  }

  listWristInfo(input: string) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listInfoWristbandTotal', '&input=' + input);
  }

  // redsysNumberInfo(input: string) {
  //   this.eventId = JSON.parse(localStorage.getItem('eventId'));
  //   let query = '?authorizationCode=' + input

  //   return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/movementPaymentAttempt' + query);
  // }

  redsysNumberInfo(input: string) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    let query = '?authorizationCode=' + input

    return this.apiService.getSimple('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/movementPaymentAttempt' + query);
  }

  //Dashboard
  fetchCreditMovesDateTotal(startingDate: string, endingDate: string, eventId?: string) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    const roleUser = JSON.parse(localStorage.getItem('roleUser'));

    if (eventId === undefined) {
      this.eventId = JSON.parse(localStorage.getItem('eventId'));
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/creditMoveReportInfoTotal', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate + '&' + 'roleUser=' + roleUser);
    } else {
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + eventId + '/creditMoveReportInfoTotal', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate + '&' + 'roleUser=' + roleUser +  '&' + 'eventId=' + eventId);
    }
  }

  getValidationControl(eventId?: string){
    if (eventId === undefined) {
      this.eventId = JSON.parse(localStorage.getItem('eventId'));
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/validationByStaff');
    }else{
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + eventId + '/validationByStaff');
    }
  }

  //Profits
  fetchCreditMoves(page: string | number, amountTy: string, order=null, sort=null) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    if ((order !== null) && (sort !== null)) {
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/creditMoveReportInfo', '&page=' + page + '&type=' + amountTy + '&orderBy=' + order + '&sortOrder=' + sort);
    } else {
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/creditMoveReportInfo', '&page=' + page + '&type=' + amountTy);
    }
  }

  fetchCreditMoves2024(page: string | number, amountTy: string, order=null, sort=null) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    if ((order !== null) && (sort !== null)) {
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/creditMoveMovemetsPG', '&page=' + page + '&type=' + amountTy + '&orderBy=' + order + '&sortOrder=' + sort);
    } else {
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/creditMoveMovemetsPG', '&page=' + page + '&type=' + amountTy);
    }
  }

  //Profits
  fetchCreditMovesAccount(page: string | number) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/creditMoveReportInfoAccount', '&page=' + page);
  }

  //Users
  fetchCreditMovesClient(page: string | number, clientId: string) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/clients/' + clientId + '/creditMoveReportInfoClient', '&page=' + page);
  }

  fetchRegisterControl(startingDate: string, endingDate: string) {
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/registerInfov2', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate);
  }

  fetchVouchers() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listEasyVoucherBlueprint');
  }

  fetchVouchersDatabase(accountId: string, eventId: string) {
    return this.apiService.get('accounts/' + accountId + '/events/' + eventId + '/listEasyVoucherBlueprint');
  }

  listProductsOfVoucher(voucher: string){
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/voucher-blueprints/' + voucher + '/listProductsToVoucher', '');
  }

  desattachCategoryOfStaffMemberUser(staffMember: string, categoriesId: string){
    return this.apiService.delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/staff-members/' + staffMember + '/product-categories/' + categoriesId + '/deleteCategory', '');
  }

  storeVouchers(voucher: any, productsAttach: any[]) {
    productsAttach.sort((a, b) => {
      return a['productCategory'] < b['productCategory'] ? -1 : 1
    });
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/easyVoucherBlueprint', {voucher: voucher, productsAttach: productsAttach})
      .subscribe(data => {
        this.voucherService.addNewVoucher(data);
      },
      err => {
        console.error(err);
      });
  }

  updateVoucherBlueprint(voucher: any,  id: string, productsAttach: any[], productsDesattach: any[]) {
    this.voucherService.editVoucher(voucher, id);
    return this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/voucher-blueprints/' + id + '/updateVoucherBlueprint', {voucher: voucher, productsAttach: productsAttach, productsDesattach: productsDesattach});
  }

  deleteVoucherBlueprint(id: string) {
    return this.apiService
      .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/voucher-blueprints/' + id);
  }

  fetchWorkers() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/staffMemberEventFast');
  }

  deleteWorker(id: string) {
    return this.apiService
      .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/staff-members/' + id);
  }

  fetchUsers() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listUsersFast')
      .subscribe(
        data => {
          this.userService.setusers(data);
        }, err => {
          console.error(err);
        });
  }

  fetchUsers2() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listUsersFast');
  }

  fetchUserAcces2(wristbandId: string) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/wristbands/' + wristbandId + '/rooms');
  }

  getAccessClient(clientId: string){
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/clients/' + clientId + '/listAccessOfClient');
  }

  grantAccessUser(wristbandId: string, roomIds: any[]) {
    const roomsAccessPass = {roomIds : roomIds};
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/wristbands/' + wristbandId + '/rooms', roomsAccessPass)
      .subscribe(_response => {
        },
        err => {
          console.log(err);
        });
  }

  clearAccessUser(wristbandId: string, roomIds: any[]) {
    const roomsAccessPass = {roomIds : roomIds};
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/wristbands/' + wristbandId + '/rooms' + '/clear', roomsAccessPass)
      .subscribe(_response => {},
        err => {
          console.log(err);
        });
  }

  importWorkers(file: File) {
    return this.apiService
      .upload('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/importStaff', file);
  }

  importProducts(file: File) {
    return this.apiService
      .upload('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/importProduct', file);
  }

  importQrs(file: File, roomId: string) {
    return this.apiService
      .upload('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/rooms/' + roomId + '/importQrsByRoom', file);
  }

  storeWorkers(worker: { [x: string]: any; }) {
    const workerPass = {name: worker['name'], surname: worker['surname'], password: worker['password'], usuario: worker['username'], roleId: worker['roleId'].id, categories: worker['categories'], hasExactPurchases: worker.hasExactPurchases};
    return this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/newStaff&User', workerPass);
  }

  storeWorkers2(worker) {
    this.worker = worker;
    this.workerPass = {name: this.worker['name'], surname: this.worker['surname'], password: this.worker['password'], usuario: this.worker['username'], roleId: this.worker['roleId'].id, email: this.worker['email']};

    return this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/newStaff&User', this.workerPass);
  }


  deleteUser(user: string){
    return this.apiService
      .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/users/' + user + '/deleteUser');
  }

  updateWorker(worker: { [x: string]: any; }, id: string) {
    const workerEditPass = {roleId: worker['roleId'], name: worker['name'], surname: worker['surname'], username: worker['username'], password: worker['password'], categories: worker['categories'], hasExactPurchases: worker['hasExactPurchases']};
    return this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/staff-members/' + id + '/updateStaffMemberuser', workerEditPass);
  }

  updateUser(id: string, infoWallet: any) {
    for (let i = 0; i < infoWallet.length; i++) {
      const userEditPass = {wallet: infoWallet[i].walletId, voucher: infoWallet[i].addVouchers};
      this.apiService
        .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/users/' + id + '/updateUser', userEditPass).subscribe(_data => {
        },
        err => {
          console.error(err);
        });
    }
  }

  updateMoney(infoWallet: any) {
    for (let i = 0; i < infoWallet.length; i++) {
      this.apiService
        .post('wristbands/' + infoWallet[i].wristbandId + '/updateWallet', { money: infoWallet[i].money }).subscribe(_data => {
        },
        err => {
          console.error(err);
        });
    }
  }

  updateUserWorker(user, id) {
    this.userId = id;
    const dataSend = {email: user['email'], password: user['password'], rol: user['roleId']};
    this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/users/' + this.userId + '/updateUserWorker', dataSend)
      .subscribe(_data => {
        },
        err => {
          console.error(err);
        });
  }

  updateUserPassword(user: { [x: string]: any; }, id: string) {
    const userPassword = {password: user['password']};
    this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/users/' + id + '/updateUser', userPassword)
      .subscribe(_data => {
          this.fetchUsers();
        },
        err => {
          console.error(err);
        });
  }

  updateEventUser(ruta: any, extraFields: any) {
    this.apiService.post(ruta, extraFields).subscribe(_data => {
        console.log(_data)
        },
        err => {
          console.error(err);
    });
  }

  userPushNotification(notify: any[]) {
    const notifyPass = {title: notify['subject'], body: notify['bodytext'], click_action: notify['action'], icon: null , tokens: notify['tokens'], timeToSend: notify['timeToSend']};
    this.apiService
    .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/SendNotification', notifyPass)
    .subscribe(data => {
      this.userService.setNotifyPushResponse(data);
    }, err => {
      console.error(err);
    });
  }

  userMailNotification(notify: any[]) {
    const notifyMailPass = {subject: notify['subject'], plaintext_body: notify['bodytext'], sender_email: 'notificaciones@bracelit.es', recipient_emails: notify['mails'], timeToSend: notify['timeToSend']};
    if (this.eventId === '04260fda-0945-42c8-9936-cbd73c2bfa80'){
      this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/MailingFestivalIdeas', notifyMailPass)
      .subscribe(data => {
        this.userService.setNotifyMailResponse(data);
      }, err => {
        console.error(err);
      });
    } else{
      this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/Mailing', notifyMailPass)
      .subscribe(data => {
        this.userService.setNotifyMailResponse(data);
      }, err => {
        console.error(err);
      });
    }
  }

  updateUserUsesLeft(uses: any[], voucher: any, id: string) {
    const usesEditPass = {voucher: voucher, uses: uses};
    this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/users/' + id + '/updateUsesLeft', usesEditPass)
      .subscribe(_data => {
        this.fetchUsers();
        },
        err => {
          console.error(err);
        });
  }

  updateUserUsesLeftDataBase(uses: any[], voucher: any, accountId: any, eventId: any) {
    const usesEditPass = {voucher: voucher, uses: uses};
    return this.apiService
      .patch('accounts/' + accountId + '/events/' + eventId + '/updateUsesLeft', usesEditPass);

  }


  createVouchers(wristbandId: any, voucherBlueprintId: string[], accountId: any, eventId: any) {
    return this.apiService
    .post('accounts/' + accountId + '/events/' + eventId + '/wristbands/' + wristbandId + '/vouchers',
    {
      clientId: '8d3ff363-cb9e-4f01-b482-10ed8c3174fd',
      // clientId: '7f52c3ea-f576-4141-9ca0-a924e7d9ace5',
      voucherBlueprintIds: voucherBlueprintId
    });
  }

  addVoucherAutomatically(voucherId: string, wallets: any[], clients: any[]) {
    const dataJSON = {wallets: wallets, clients: clients};
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/voucher-blueprints/' + voucherId + '/rechargeVoucherAutomatically', dataJSON)
      .subscribe(_data => {
        },
        err => {
          console.error(err);
        });
  }

  fetchRoles() {
    this.apiService
      .get('roles').subscribe(
      data => {
        this.workerService.setRoles(data);
      },
      err => {
        console.error(err);
      });
  }

  fetchRooms() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/rooms');
  }

  storeRooms(room: any) {
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/rooms', room)
      .subscribe(response => {
          const respon = room;
          respon.id = response.id;
          this.roomService.addNewRoom(room);
        },
        err => {
          console.error(err);
        });
  }

  updateRoom(room: any, id: string) {
    this.roomService.editRoom(room, id);

    return this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/rooms/' + id, room);
  }

  deleteRoom(id: string) {
    return this.apiService
      .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/rooms/' + id);
  }

  fetchGroups() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listGroup');
  }

  storeGroups(group: any) {
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/createGroup', group)
      .subscribe(response => {
        this.groupService.addNewGroup(response);
        },
        err => {
          console.error(err);
        });
  }

  updateGroup(group: any, id: string) {
    this.groupService.editGroup(group, id);

    return this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/groups/' + id + '/updateGroup', group);
  }

  deleteGroup(id: string) {
    return this.apiService
      .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/groups/' + id + '/deleteGroup');
  }

  fetchActivities() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listActivity');
  }

  storeActivities(activity: { name: any; maxCapacity: any; autoAccessEnable: any; group: { id: any; }; date: any; }) {
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/createActivity', {name: activity.name, maxCapacity: activity.maxCapacity, autoAccessEnable: activity.autoAccessEnable, group: activity.group.id, date: activity.date})
      .subscribe(response => {
        this.activitiesService.addNewActivity(response);
        },
        err => {
          console.error(err);
        });
  }

  updateActivity(activity: { name: any; maxCapacity: any; autoAccessEnable: any; group: { id: any; }; date: any; }, id: string, lastGroup: string) {
    this.activitiesService.editActivity(activity, id);

    return this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/activities/' + id + '/updateActivity', {name: activity.name, maxCapacity: activity.maxCapacity, autoAccessEnable: activity.autoAccessEnable, group: activity.group.id, lastGroup: lastGroup, date: activity.date});
  }

  deleteActivity(id: string) {
    return this.apiService
      .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/activities/' + id + '/deleteActivity');
  }

  storeGroupFromActivity(activity: string) {
    return this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/activities/' + activity + '/listGroupFromActivity', '');
  }

  exitPeople(id: string) {
    return this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/rooms/' + id + '/exitPeople', {});
  }

  fetchMovesRooms(startingDate: string, endingDate: string, room?: string) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    if ((room !== null) && (room !== undefined) && (room !== '')) {
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/eventRoomsFast', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate + '&' + 'roomFilter=' + room);
    } else {
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/eventRoomsFast', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate);
    }
  }

  fetchMovesActivities(startingDate?: string, endingDate?: string, page?: number) {
    if ((startingDate !== null) && (endingDate !== null)) {
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/eventActivitiesFast', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate);
    } else {
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/eventActivitiesFast', '&page=' + page);
    }
  }

  fetchMovesRoomsAccount(startingDate: string, endingDate: string) {
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId +  '/eventRoomsWithAccount', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate);
  }

  fetchMovesRoomsPage(page: number, order=null, sort=null, type=null) {
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/eventRooms', '&page=' + page + '&orderBy=' + 'RoomAccess.' + order + '&sortOrder=' + sort + '&type=' + type);
  }

  fetchMovesRoomsPageWithAccount(page: string | number) {
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/eventRoomsWithAccount', '&page=' + page);
  }

  getSaleOrderLine(creditableId: string) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    const params = `?creditableId=${creditableId}`
    return this.apiService.getSimple('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/getSaleOrderLine'+ params);
  }

  updateInfoForUsers(info: any) {
    this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/updateInfoForUsers', info)
      .subscribe(_data => {
        },
        err => {
          console.error(err);
        });
  }

  rechargeNotifyNowallet(pushLeft: number, mailLeft: number, eventId: string) {
    const rechargePassNoWallet = {mailTorecharge: mailLeft, pushTorecharge: pushLeft};
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + eventId  + '/recharge-notifications' , rechargePassNoWallet)
      .subscribe(_data => {
        },
        err => {
          console.error(err);
        });
  }

  fecthAdmininfo() {
    const adminId = JSON.parse(localStorage.getItem('userId'));
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/users/' + adminId);
  }

  modifyCreditMove(moveId: any, amount: any) {
    return new Promise<void>((resolve, reject) => {
      this.apiService
        .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/creditMove', {
          creditMoveId: moveId,
          newAmount: amount
        }).toPromise().then(
        res => { // Success
          resolve();
          const newAmount = res.amount;
        },
        msg => { // Error
          reject(msg);
          console.log(msg);
          this.errorMoveEdit = msg;
          this.profitsService.hasErrorMsg = true;
        });
    });
  }

  deleteCreditMove(move: { creditMove: { id: string; }; client: any[]; }) {
    return new Promise<void>((resolve, reject) => {
      this.apiService
        .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/creditMove/' + move.creditMove.id, {
          client: move.client[0]
        })
        .toPromise().then(
        res => { // Success
          const newAmount = res.json().results;
          resolve();
        },
        msg => { // Error
          reject(msg);
          console.log(msg);
          this.errorMoveEdit = msg;
          this.profitsService.hasErrorMsg = true;
        });
    });
  }

  downloadCSV(id: number) {
    if (id === 1) {
      return this.apiService.download('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/exportCMCSV');
    } else {
      return this.apiService.download('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/exportAccessCSV');
    }
  }

  downloadExcel(value: string) {
    return this.apiService.download('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/' + value);
  }

  downloadBoxControl(){
    return this.apiService.download('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/exportPalcosPolo');
  }

  downloadReportsDownload(){
    return this.apiService.download('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/exportReport4CSV');
  }

  downloadConsignment(){
    return this.apiService.download('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/exportRemesaCSV');
  }

  downloadReportsDownloadDate(startingDate: string, endingDate: string) {
    const roleUser = JSON.parse(localStorage.getItem('roleUser'));
    return this.apiService.download('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/exportReport4CSV', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate);
  }

  fetchWristListByRooms() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listWristbandRooms').toPromise();
  }
  fetchTicketListByRooms() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/tickets/getTicketRooms').toPromise();
  }

  fetchOrders(onlinePlace: string) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    if (!onlinePlace) {
      onlinePlace = '';
    }

    return this.apiService.get(`accounts/${this.authService.getAccountId()['id']}/events/${this.eventId}/onlineOrders`, 'query=&onlinePlace=' + onlinePlace);
  }

  listOrderPlaces() {
    const accountId = this.authService.getAccountId()['id'];
    const eventId =  JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get(`accounts/${accountId}/events/${eventId}/onlineOrderPlaces`);
  }

  createOrderPlace(data: { name: any; }) {
    const accountId = this.authService.getAccountId()['id'];
    const eventId =  JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.post(`accounts/${accountId}/events/${eventId}/newOnlineOrderPlace`,
    {name: data.name}).subscribe(_response => {
    },
    err => {
      console.error(err);
    });
  }

  updateOrderPlace(orderPlace: string, placeEdit: any) {
    return this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/onlineOrderPlaces/' + orderPlace, placeEdit);
  }

  deleteOrderPlace(id: string) {
    return this.apiService
      .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/onlineOrderPlaces/' + id);
  }

  updateOrder(order: string, value: any) {
    const orderEdit = {status: value};
    return this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/onlineOrders/' + order, orderEdit);
  }

  updateOrderCancel(order: string, value: any, reason: any) {
    const orderEdit = {status: value, reason: reason};
    return this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/onlineOrders/' + order, orderEdit);
  }

  postImport(file: File){
    return this.apiService.upload('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/importUser/',file)
  }

  fetchCreditMovesGraph(startingDate: string, endingDate: string, worker?: string, category?: string, product?: string, eventId?: string) {
    if((this.authService.getAccountId()['id'] !== null) && (this.authService.getAccountId()['id'] !== undefined)) {
      if ((worker !== null) && (worker !== undefined)) {
        return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/graphCreditMoves', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate + '&' + 'workerFilter=' + worker);
      } else if ((category !== null) && (category !== undefined)) {
        return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/graphCreditMoves', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate + '&' + 'categoryFilter=' + category);
      } else if ((product !== null) && (product !== undefined)) {
        return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/graphCreditMoves', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate + '&' + 'productFilter=' + product);
      } else if ((eventId !== null) && (eventId !== undefined)) {
        return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + eventId + '/graphCreditMoves', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate + '&' + 'eventId=' + eventId);
      } else {
        return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/graphCreditMoves', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate);
      }
    }
  }

  fetchAccessGraphV2(startingDate: string, endingDate: string, room?: string) {
    if(this.authService.getAccountId()['id'] && this.eventId) {
      if ((room !== null) && (room !== undefined) && (room !== '')) {
        return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/graphAccessV2', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate + '&' + 'roomFilter=' + room);
      } else {
        return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/graphAccessV2', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate);
      }
    }
  }

  importWegowTickets(modifiedSince: string) {
    return this.apiService
    .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/tickets/wegowImport','query=&modifiedSince=' + modifiedSince)
  }

  importTixxlabTickets(modifiedSince: string) {
    return this.apiService
    .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/tickets/tixxlabImport','query=&modifiedSince=' + modifiedSince)
  }

  listTicketTypeRoom(roomId: string){
    return this.apiService
    .get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/tickets/rooms/' + roomId +'/ticketAssociation').toPromise()
  }

  editTicketTypeRoom(roomId: string,accessArray: any){
    return this.apiService
    .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/tickets/rooms/' + roomId +'/attachTicketType',{ticketType:accessArray}).toPromise()
  }

  getIntervalReturn(){
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService
    .get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId +'/intervalMoneyReturn')
  }

  addRoomToValidationGroup(roomId: string,action: any){
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService
    .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/setCrossAccess',{roomId:roomId,option:action})
  }

  forwardTicket(email: any) {
    return this.apiService.post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/sendSingleQR', {email: email, ticket: true});
  }

  fetchDevices(){
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService
    .get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId +'/listDevices')
  }

  editDevice(deviceId: string,name: any,model: any){
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService
    .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId +'/devices/' + deviceId, {model:model,name:name})
  }

  editBoxlit(deviceId: string, info: any){
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService
    .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId +'/devices/' + deviceId + '/editBoxlit', info)
  }

  fetchBoxlits(){
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService
    .get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId +'/listBoxlit')
  }

  searchTicket(value: string) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService
    .get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId +'/searchTicketStatus', value);
  }

  ticketReport(roomId: string =null){
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService
    .get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId +'/tickets/report','query=&roomId=' + roomId)
  }

  resendTicketInditex(value: any) {
    const data = {email: value}
    return this.apiService
    .downloadPDF('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/resendTicketInditex', 'query=&email=' + value)
  }

  editDataWristband(wristband: any, info: any) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService
      .post('accounts/' + wristband.accountId + '/events/' + this.eventId + '/wristbands/' + wristband.id + '/patchWristband', {info: info})
  }

  fetchStaffMemberUserId() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    const staffName = JSON.parse(localStorage.getItem('userEmail'));
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/getStaffUserFromUser', '&staffName=' + staffName);
  }

  fetchLeadsGraph(value: string, startingDate: string, endingDate: string) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/staffMemberUser/' + value + '/graphLeadsTotal', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate);
  }

  fetchLeadsClasification(value: string, startingDate: string, endingDate: string) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/staffMemberUser/' + value + '/clasificationLeads', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate);
  }

  downloadReport() {
    return this.apiService.download('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/exportLeadScannerPG');
  }

  associateWristbandToEmail(wristbandId: string, email: any){
    let eventId = JSON.parse(localStorage.getItem('eventId'));
    return this.apiService
    .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + eventId + '/wristbands/' + wristbandId + '/associateToEmail',{email: email})
  }

  personNotAccess(roomId: string) {
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/rooms/' + roomId + '/getWristNotAccess');
  }

  personWithAccess(roomId: string) {
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/rooms/' + roomId + '/getWristWithAccess');
  }

  sendInvitationMail(fileEmails: any) {
    let numEntradas: any = 1;
    const formData: FormData = new FormData();
    formData.append('file', fileEmails);
    formData.append('numEntradas', numEntradas);

    return this.apiService.post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/sendEmailsByExcel', formData);
  }

  sendInvitationAbanca(fileEmails: any, logo:FormData, image: FormData, subject: any, bodyText: any, background: any, typeNotification: any, timeToSend: any) {
    let numEntradas: any = 1;
    const formData: FormData = new FormData();
    formData.append('file', fileEmails);
    formData.append('numEntradas', numEntradas);
    formData.append('subject', subject);
    formData.append('bodyText', bodyText);
    formData.append('typeNotification', typeNotification);
    formData.append('backgroundColor', background);
    console.log(timeToSend);
    if(timeToSend !== undefined && timeToSend !== null) {
      formData.append('timeToSend', timeToSend);
    }
    
    this.apiService.post('files/' + this.eventId + '/uploadInvitationLogo', logo).subscribe((response) => {
      this.apiService.post('files/' + this.eventId + '/uploadInvitationFile', image).subscribe((response) => {
        this.apiService.post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/sendEmailsByExcel', formData).subscribe((data) => {
          this.authService.setLoader(false);
          this.snackbarService.openSnackBar('Email enviado con éxito.');
        },
        err => {
          console.error(err);
        });
      },
      err => {
        console.error(err);
      });
    },
    err => {
      console.error(err);
    });
  }

  downloadTickets(voucherBlueprintId: string, value: any) {
    return this.apiService
    .downloadPDF('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/voucher-blueprints/' + voucherBlueprintId + '/reserveVouchersFex', 'query=&numEntradas=' + value.numEntradas);
  }
}
