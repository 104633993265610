<div style="display: flex; flex-direction: column; align-items: center; justify-content: center; color: #0F223E">
  <h2 mat-tab-title style="margin: 20px;" class="primary-color" fxLayoutAlign="center center" fxLayout="row wrap">Envío de invitaciones</h2>
  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
    <div id="logo" class="custom-input-file" [ngStyle]="{'color': fileLogoName ? '#fff' : '#0F223E', 'backgroundColor': fileLogoName ? '#0F223E' : '#fff'}">
      <input #file class="input-file" type="file" value="" accept=".jpg, .png"
            (change)="handleFileLogoInput($event.target.files)">Logo mail
    </div>
    <div>{{fileLogoName}}</div>
  </div>
  <form [formGroup]="myForm" style="width: 100%; margin-top: 30px;">
    <div style="display: flex; flex-direction: column; align-items: stretch; margin-bottom: 15px; width: 100%; border-radius: 5px; border: 1px solid #88A9BB;">
      <input
        style="width: calc(100% - 25px); border: none; outline: none; padding: 10px; font-size: 16px;"
        id="subject"
        placeholder="Asunto del mail"
        formControlName="subject"
      >
    </div>
    <label class="primary-color" style="margin-right: 0px; font-size: 16px; width: 100%; text-align: left">
      Hola, *nombre*</label>
    <div style="display: flex; flex-direction: column; align-items: stretch; margin-bottom: 15px; width: 100%; border-radius: 5px; border: 1px solid #88A9BB;">
      <textarea
        style="width: calc(100% - 25px); min-height: 38px; border: none; background-color: rgba(240, 248, 255, 0); color: black; outline: none; padding: 10px; font-size: 16px; resize: vertical;"
        id="bodytext"
        placeholder="Texto del cuerpo del mensaje después del saludo"
        formControlName="bodytext"
      ></textarea>
    </div>
    <label [for]="'typeNotification'" class="primary-color" style="margin-right: 0px; font-size: 16px; width: 100%; text-align: left">
      Invitación adjunta en formato PDF*</label>
      <div
          style="display: flex; justify-content: left; align-items: center; margin-bottom: 30px; width: 100%; height: 25px; border-radius: 5px; border: 0px solid #88A9BB;">
      <input
      style="height: 100%; border: none; background-color: rgba(240, 248, 255, 0); color: black; outline: none; padding: 0 10px; font-size: 16px"
      id='typeNotification'
      type="checkbox"
      formControlName='typeNotification'>
    </div>
  </form>
  <div style="margin: 20px 0; font-size: 16px;">
    {{message}}
  </div>
  <div style="display: flex; flex-direction: row; gap: 40px;">
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
      <mat-icon class="help" svgIcon="help"
        matTooltip="Ver ejemplo"
        matTooltipPosition="left"
        #tooltip1="matTooltip" (click)="toggle(1)"
        style="width: 100% !important; height: 17px !important; margin-bottom: 7px; cursor: pointer;"></mat-icon>
      <div class="custom-input-file" [ngStyle]="{'color': fileExcelName ? '#fff' : '#0F223E', 'backgroundColor': fileExcelName ? '#0F223E' : '#fff'}">
        <input #file class="input-file" type="file" value="" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
               (change)="handleFileExcelInput($event.target.files)">Listado invitados
      </div>
      <div>{{fileExcelName}}</div>
      <mat-error *ngIf="typeExcelError" style="width: 250px">Error: {{errorExcelMsg}}</mat-error>
    </div>
    <div *ngIf="myForm.value['typeNotification']" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
      <mat-icon class="help" svgIcon="help"
        matTooltip="Ver ejemplo"
        matTooltipPosition="left"
        #tooltip2="matTooltip" (click)="toggle(2)"
        style="width: 100% !important; height: 17px !important; margin-bottom: 7px; cursor: pointer;"></mat-icon>
      <div class="custom-input-file" [ngStyle]="{'color': fileTemplateName ? '#fff' : '#0F223E', 'backgroundColor': fileTemplateName ? '#0F223E' : '#fff'}">
        <input #file class="input-file" type="file" value="" accept=".jpg"
               (change)="handleFileTemplateInput($event.target.files)">Template entrada
      </div>
      <div>{{fileTemplateName}}</div>
      <mat-error *ngIf="typeTemplateError" style="width: 250px">Error: {{errorTemplateMsg}}</mat-error>
    </div>    
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10"  class="submit" style="margin: 20px 0;">
    <button fxLayoutAlign="center center" class="secondary"
            [class.disabled]="(typeExcelError || (typeTemplateError && this.myForm.value['typeNotification']) || fileLogoName === '' || fileExcelName === '' || (fileTemplateName === '' && this.myForm.value['typeNotification']))"
            mat-button
            (click)="sendInvitations()">Enviar</button>
  </div>
</div>
