import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

/**
 * CanComponentDeactivate interface
 */
export interface CanComponentDeactivate {
  /**
   * canDeactivate method interface
   * @returns {Observable<boolean> | Promise<boolean> | boolean}
   */
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

/**
 * CanDeactivate Guard, prevents the user from navigating to other page
 */
@Injectable()
export class CanDeactivateGuard  {

  /**
   * CanDeactivate method
   * @param component
   * @returns {Observable<boolean> | Promise<boolean> | boolean}
   */
  canDeactivate(component: CanComponentDeactivate) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
